
























































































































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import type EditMenu from './EditMenu/index.vue'
import { format } from 'quasar'
import { sectionsStore, tasksStore } from '@/store'

const { capitalize } = format

@Component({
  name: 'RuleCard',
  components: {
    EditMenu: () => import('./EditMenu/index.vue'),
    IconEllipsisVDouble: () => import('@/components/UI/icons/IconEllipsisVDouble.vue'),
    IconPen: () => import('@/components/UI/icons/IconPen.vue'),
    IconTimes: () => import('@/components/UI/icons/IconTimes.vue'),
    RuleCardSection: () => import('./RuleCardSection.vue'),
    TaskImportanceChip: () => import('@/components/Tasks/TaskImportanceChip.vue'),
  },
})
export default class RuleCard extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly rule!: TADA.TasksColorsRule

  @Prop({
    type: Boolean,
    default: false,
  }) readonly isNew!: boolean

  isSaving = false

  @Ref() readonly editMenu!: EditMenu
  @Getter canManageTasksColorsRules!: boolean

  tasksStatusTitleByUid (uid: string): string | null {
    return tasksStore.getters.statusTitleByUid(uid)
  }

  /**
   * Color a card based on the rule color that it describes
   */
  get cardStyle (): Partial<CSSStyleDeclaration> {
    const color = tasksStore.state.colors[this.rule.colorIndex]
    return {
      backgroundColor: color.light,
      borderColor: color.regular,
    }
  }

  tasksSectionNameByUid (uid: string): string {
    return sectionsStore.getters.tasksSectionNameByUid(uid)
  }

  /**
   * Capitalize text translations
   */
  get withoutProjectText (): string {
    return capitalize(this.$t('common.withoutProject').toString())
  }

  /**
   * Capitalize text translations
   */
  get withoutTagsText (): string {
    return capitalize(this.$t('common.withoutTags').toString())
  }

  /**
   * Do not remove - it's a component's external API.
   */
  startEdit () {
    this.editMenu && this.editMenu.show()
  }

  async deleteRule () {
    this.isSaving = true
    this.isNew
      ? this.$emit('edit-finished') // simply remove it from the list
      : await tasksStore.actions.deleteColorsRule(this.rule.uid) // actually delete it
    this.isSaving = false
  }
}
